const backToTop = document.querySelector('#back-to-top');

backToTop.addEventListener('click', () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
});

const setupNewsletter2 = () => {
  const newsletter = document.querySelector('#form2');

  if (!newsletter) return;

  const createContact = (email) => {
    const options = {
      method: 'POST',
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
        'api-key':
          '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',
      },

      body: JSON.stringify({
        email: email,
      }),
    };

    fetch('https://api.brevo.com/v3/contacts', options)
      .then((response) => response.json())
      .then((response) => {
        if (response.code === 'duplicate_parameter') {
          alert('Vous êtes déjà inscrit à la newsletter');
        } else {
          alert('Merci pour votre inscription !');
        }
      })
      .catch((err) => console.error(err));
  };

  const email = document.querySelector('#email2');

  const regex = /\S+@\S+\.\S+/;

  newsletter.addEventListener('click', () => {
    if (!regex.test(email.value)) {
      alert('Merci de renseigner un email valide');
      return;
    }

    createContact(email.value);
  });
};

setupNewsletter2();
